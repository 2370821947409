/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  CloudAccountsQuery,
  CloudAccountsQueryVariables,
  useCloudAccountsQuery,
} from "~/generated/graphql";
import { useQueryState } from "~/hooks";
import { isNil } from "~/tools";
import { CloudAccountsQueryState, CloudAccountsResponse } from "../types";
import { getAccountFilters, getAccountStatusFilter } from "../utils";
import { useCloudAccountSort } from "./useCloudAccountSort";

export function useCloudAccountsFetch() {
  const { state } = useQueryState<CloudAccountsQueryState>();

  const {
    provider,
    status,
    query,
    page,
    limit,
    order,
    chargeType,
    providerFilter,
  } = state;

  const { sortDirection, sortField } = useCloudAccountSort({
    chargeType,
    order,
  });

  const fetchCloudAccountsVariables: CloudAccountsQueryVariables = {
    limit,
    page,
    order: {
      order: sortDirection,
      field: sortField,
      chargeType,
    },
    filter: {
      ...getAccountFilters(provider, providerFilter),
      ...getAccountStatusFilter(status ?? []),
    },
    query: query?.toString(),
  };

  const { data, error, loading } = useCloudAccountsQuery({
    skip: isNil(provider),
    variables: fetchCloudAccountsVariables,
  });

  return {
    data: mapResponseData(data),
    fetchCloudAccountsVariables,
    error,
    loading,
  };
}

export function mapResponseData(
  data?: CloudAccountsQuery
): CloudAccountsResponse {
  return {
    accounts: (data?.cloudAccounts?.accounts ?? []).map((account) => ({
      id: account.nid,
      nid: account.nid,
      provider: account.provider,
      providerId: account.providerId,
      name: account.name,
      displayName: account?.displayName,
      description: account?.description,
      activated: account.activated,
      metadata: account?.metadata,
      contacts: account?.contacts,
      owner: account?.owner,
      providerType: account?.providerType ?? undefined,
      chargeTypeCosts: account?.chargeTypeCosts ?? [],
      cloudStatus: account?.cloudStatusV2 ?? undefined,
    })),
    pages: data?.cloudAccounts?.pages ?? 0,
    count: data?.cloudAccounts?.count ?? 0,
  };
}
