/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Button } from "@nordcloud/gnui";
import { getCustomer } from "~/services/customers";
import { isVMWare } from "~/views/cloudAccounts/utils";
import { Account } from "../../types";
import {
  DEPLOYMENT_DESCRIPTOR,
  METRICS_EXPORTER,
  PROMETHEUS_DEPLOYMENT,
  VMWARE_CONFIG_FILE,
} from "./constants";

enum FILE_FORMAT_TYPE {
  JSON = "json",
  YAML = "yaml",
}

type Props = {
  account: Account;
  size?: "md" | "sm";
  severity?: "high" | "low" | "medium";
  status?: "discovery";
};

export function HybridCloudDownloadButton({
  account,
  size,
  severity,
  status,
}: Props) {
  const isVMWareAccount = isVMWare(account.providerType);
  const buttonText = isVMWareAccount
    ? "Download Config File"
    : "Download Deployment YAML";

  const fileName = getFileName(account);
  const fileContents = generateFileContents(account);

  return (
    <Button
      size={size}
      severity={severity}
      status={status}
      icon="download"
      // @ts-expect-error Button should be enhanced with download attribute;
      download={fileName}
      linkTo={`data:application/octet-stream;charset=utf-16le;base64,${fileContents}`}
    >
      {buttonText}
    </Button>
  );
}

const isDeploymentWithMetrics = (account: Account): boolean => {
  const metadata = JSON.parse(account.metadata ?? "{}");

  return metadata?.storage !== undefined;
};

const getDeploymentProperties = (account: Account) => {
  const metadata = JSON.parse(account.metadata ?? "{}");
  const accountId = account?.id;
  const customer = getCustomer();

  return isVMWare(account.providerType)
    ? new Map([
        [`<CUSTOMER_ID>`, customer?.id ?? ""],
        [`<ACCOUNT_ID>`, accountId],
        [`<STORAGE_NAME>`, metadata?.storageName],
        [`<SAS_TOKEN>`, metadata?.sasToken],
      ])
    : new Map([
        [`<INSERT_SAS_TOKEN>`, metadata?.sasTokens?.primary],
        [`<INSERT_QUEUE_NAME>`, metadata?.queueName],
        [`<INSERT_SCANNER_KEY>`, metadata?.scannerKey],
        [`<INSERT_CUSTOMER_ID>`, customer?.id ?? ""],
        [`<INSERT_CLOUD_ACCOUNT>`, accountId],
        [`<INSERT_PLATFORM>`, account.providerType],
        [`<INSERT_PROVIDER>`, account.provider],
        [`<INSERT_NAMESPACE>`, `nordcloud-scanner`],
        [`<INSERT_STORAGE_NAME>`, metadata?.storage?.storageName],
        [`<INSERT_STORAGE_SAS_TOKEN>`, metadata?.storage?.sasToken],
      ]);
};

const getFileName = (account: Account): string => {
  const isVMWareAccount = isVMWare(account.providerType);

  const fileName = isVMWareAccount ? "config" : account.name;
  const fileFormat = isVMWareAccount
    ? FILE_FORMAT_TYPE.JSON
    : FILE_FORMAT_TYPE.YAML;

  return fileName
    ? `${fileName.replaceAll(/[^\w-]/g, "")}.${fileFormat}`
    : `default.${fileFormat}`;
};

const generateFileContents = (account: Account) => {
  const isDeployMetrics = isDeploymentWithMetrics(account);
  const deploymentKubernetes = isDeployMetrics
    ? DEPLOYMENT_DESCRIPTOR.concat(METRICS_EXPORTER, PROMETHEUS_DEPLOYMENT)
    : DEPLOYMENT_DESCRIPTOR;

  const isVMWareAccount = isVMWare(account.providerType);
  const templateFile = isVMWareAccount
    ? VMWARE_CONFIG_FILE
    : deploymentKubernetes;

  const deploymentProperties = getDeploymentProperties(account);

  let finalFile = templateFile;
  for (const key of deploymentProperties.keys()) {
    finalFile = finalFile.replaceAll(
      new RegExp(key, "g"),
      deploymentProperties.get(key) ?? ""
    );
  }

  return btoa(finalFile);
};
